<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "app",
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear'
    })
  },
  watch: {
    $route() {
      this.clearAlert();
    }
  }
};
</script>

<template>
  <div id="app">
    <RouterView  />
  </div>
</template>
